// Step 1: Import React
import * as React from 'react'
import Hero from '../components/hero'
import Person from '../components/person'
import Client from '../components/client'
import Testimonial from '../components/testimonial'
import Integration from '../components/integration'
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'

const query = graphql`{
    contentfulHomepage {
      heroSubtext
      heroTitle
      id
      heroImage {
        gatsbyImageData(layout: CONSTRAINED)
      }
      whatWeDoSubtitle {
        raw
      }
      whatWeDoColumn1 {
        raw
      }
      whatWeDoColumn2 {
        raw
      }
      whatWeDoAppHeader
      whatWeDoApp {
        raw
      }
      whatWeDoSalesforceConsultancyHeader
      whatWeDoSalesforceConsultancy {
        raw
      }
      ourClientsSubtitle {
        raw
      }
      ourIntegrationsSubtitle {
        raw
      }
      ourTeamSubtitle {
        raw
      }
      contactUsWeAreHiring {
        raw
      }
      contactUsSubtitle {
        raw
      }
    }
    allContentfulTeamMember(sort: {order: ASC, fields: order}) {
      nodes {
        name
        id
        order
        title
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
        description {
          raw
        }
      }
    }
    allContentfulClient(sort: {fields: order, order: ASC}) {
      nodes {
        id
        name
        order
        testimonial {
          raw
        }
        showTestimonial
        testimonialGiver
        testimonialGiverTitle
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    allContentfulIntegration(sort: {fields: order, order: ASC}) {
      nodes {
        id
        name
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const richTextOptions1 = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <i className="italic">{text}</i>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="font-bold">{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className="font-bold text-xl">{children}</h3>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p>{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal">{children}</ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
  },
}

// different p text size
const richTextOptions2 = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <i className="italic">{text}</i>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="font-bold">{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className="font-bold text-xl">{children}</h3>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="text-sm">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal">{children}</ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
  },
}

// OL_LIST, UL_LIST, LIST_ITEM, HEADING_2, HEADING_3, HEADING_4 

// Step 2: Define your component
const IndexPage = () => {
  const data = useStaticQuery(query);
  const homepage = data.contentfulHomepage;
  const teamMembers = data.allContentfulTeamMember.nodes;
  const clients = data.allContentfulClient.nodes;
  const testimonials = data.allContentfulClient.nodes;
  const integrations = data.allContentfulIntegration.nodes;
  return (
    <main>
      <title>Darlston</title>
      <Hero homepage={homepage} />
      <div className="xl:container divide-y mx-auto">
        <div className="lg:py-10 lg:py-10 flex flex-col lg:flex-row lg:divide-x lg:divide-red-500">
          <div className="p-5 md:p-10 sm:basis-full  md:basis-1/3">
            <StaticImage
              className="mb-8"
              src="../images/Darlston-Logo.png"
              alt="Darlston Logo"
              layout="fixed"
              width={200}
            />
            <h2 className="pb-3 text-2xl font-bold underline underline-offset-4">What We Do</h2>
            <p className="md:pr-3">{renderRichText(homepage.whatWeDoSubtitle, richTextOptions1)}</p>
          </div>
          <div className="p-5 sm:basis-full  md:basis-2/3">
          <div className="flex flex-col md:flex-row">
            <div className="md:p-5 sm:basis-full  md:basis-1/2">
              <div>{renderRichText(homepage.whatWeDoColumn1, richTextOptions2)}</div>
            </div>
            <div className="md:p-5 sm:basis-full  md:basis-1/2">
              <div>{renderRichText(homepage.whatWeDoColumn2, richTextOptions2)}</div>
            </div>
          </div>
          </div>
        </div>
        <div className="md:py-10 md:py-10 md:px-5 md:px-10 flex flex-col md:flex-row">
          <div className="p-5 sm:basis-full  lg:basis-1/3">
            <h2 className="pb-3 text-2xl font-bold underline underline-offset-4">Our Team</h2>
            <p className="lg:pr-7">{renderRichText(homepage.ourTeamSubtitle)}</p>
          </div>
          <div className="p-5 sm:basis-full  lg:basis-2/3 flex flex-wrap">
            {
              teamMembers.map((teamMember)=>{
                return <Person data={teamMember} />
              })
            }
          </div>
        </div>
        <div className="md:py-10 md:py-10 md:px-5 md:px-10 flex flex-col md:flex-row">
          <div className="p-5 sm:basis-full  lg:basis-1/3">
            <h2 className="pb-3 text-2xl font-bold underline underline-offset-4">Clients</h2>
            <p className="lg:pr-7">{renderRichText(homepage.ourClientsSubtitle)}</p>
          </div>
          <div className="p-5 sm:basis-full  lg:basis-2/3 flex flex-nowrap overflow-x-scroll">
            {
              clients.map((client)=>{
                return <Client data={client} />
              })
            }
          </div>
        </div>
        <div className="md:py-10 md:py-10 md:px-5 md:px-10 flex flex-col md:flex-row">
          <div className="p-5 sm:basis-full  lg:basis-1/3">
            <h2 className="pb-3 text-2xl font-bold underline underline-offset-4">What We Work With</h2>
            <p className="lg:pr-7">{renderRichText(homepage.ourIntegrationsSubtitle)}</p>
          </div>
          <div className="p-5 sm:basis-full lg:basis-2/3 flex flex-wrap">
            {
              integrations.map((integration)=>{
                return <Integration data={integration} />
              })
            }
          </div>
        </div>
      </div>
      <div className="bg-slate-700">
        <div id="contact-us" className="text-white container divide-y mx-auto">
          <div className="md:py-10 md:py-10 md:px-5 md:px-10 flex flex-col md:flex-row md:divide-x md:divide-red-500">
              <div className="p-5 sm:basis-full  lg:basis-1/3">
                <h2 className="pb-3 text-2xl font-bold underline underline-offset-4">Contact Us</h2>
                <p className="lg:pr-7">{renderRichText(homepage.contactUsSubtitle)}</p>
              </div>
              <div className="p-5 sm:basis-full  lg:basis-2/3">
              <div className="flex flex-col md:flex-row">
                <div className="md:p-5 sm:basis-full  lg:basis-1/2">
                  <h3 className="text-lg font-bold">Email</h3>
                  <a href="mailto:info@darlston.co.uk" className="text-white">info@darlston.co.uk</a>
                  {/*
                  <h3 className="text-lg font-bold mt-5 ">Phone</h3>
                  <p>01953 859100</p>
                  */}
                  <h3 className="pt-5 text-lg font-bold">Address</h3>
                  <p>
                    Hethel Engineering Centre<br/>
                    Chapman Way<br/>
                    Norwich<br/>
                    Norfolk<br/>
                    NR14 8FB
                  </p>
                </div>
                <div className="md:p-5 sm:basis-full  lg:basis-1/2">
                  <h3 className="text-lg font-bold">We're Hiring!</h3>
                  <p>{renderRichText(homepage.contactUsWeAreHiring)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
// Step 3: Export your component
export default IndexPage