import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Hero = (props) => {

    // https://www.gatsbyjs.com/plugins/gatsby-background-image/
    const image = getImage(props.homepage.heroImage.gatsbyImageData)
    const bgImage = convertToBgImage(image)

    return (
        <div className="">
            <BackgroundImage
                Tag="section"
                className="h-96"
                {...bgImage}
                backgroundColor={`#040e18`}
            >
                <div className="p-16">
                    <h1 className="text-right font-extrabold text-white text-white-900 text-2xl md:text-3xl lg:text-4xl">Helping charities <span className="text-gray-400">innovate<br/>develop<br/>grow</span></h1>
                    <button onClick={() => scrollTo('#contact-us')} className="float-right px-6 py-2 rounded bg-sky-500 hover:bg-sky-600 text-white font-bold">Contact Us</button>
                    {/* <h1 className="text-4xl text-right font-extrabold text-white text-white-900 sm:text-5xl md:text-6xl">
                        {props.homepage.heroTitle}
                    </h1>
                    <p className="font-sans text-right float-right text-white text-white-500">
                        {props.homepage.heroSubtext}
                    </p> */}
                </div>
            </BackgroundImage>
        </div>
    )
}

export default Hero