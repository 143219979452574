import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Client = (props) => {
  const image = getImage(props.data.image)
  return (
    <div className="m-2 p-5 box-border min-w-[180px] shadow-md border border-solid border-gray-200 rounded-md">
        <h3 className="mb-5 font-bold">{props.data.name}</h3>
        <GatsbyImage image={image} alt={props.data.name} className="object-cover rounded-md"/>
    </div>
  )
}

export default Client