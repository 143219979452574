import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Integration = (props) => {
  const image = getImage(props.data.image)
  return (
    <div className="m-2 p-5 box-border w-36 h-36 shadow-md border border-solid border-gray-200 rounded-md flex flex-col justify-center items-center">
        {/* <h3 className="font-bold">{props.data.name}</h3> */}
        <GatsbyImage image={image} alt={props.data.name} className=""/>
    </div>
  )
}

export default Integration